import Swiper from 'swiper';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import ImageCompare from 'image-compare-viewer';

import Inputmask from './vendor/inputmask/inputmask.es6';

import * as bi from './functions/bi';
import { isMobile, scrollTo, isInViewport, imagesToggleSlide, ticker } from './functions/main';
import { initializeFluidSimulation } from './functions/fluidSimulation';
import { initBid } from './functions/bid/main';

// Настройка свайпера для мобильной вёрстки
const mobileSwiper = className => new Swiper(className, {
  modules: [Pagination, EffectCoverflow],
  slidesPerView: 'auto',
  centeredSlides: true,
  spaceBetween: -90,
  loop: true,
  effect: 'coverflow',
  coverflowEffect: {
    rotate: 0,
    scale: 0.6
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  }
});

// Настройка свайпера для главной страницы
// eslint-disable-next-line no-unused-vars
const directionsSwiper = mobileSwiper('.directions');

// Настройка свайпера для страницы bi
// eslint-disable-next-line no-unused-vars
const biReasonsSwiper = isMobile ? mobileSwiper('.reasons')
  : new Swiper('.reasons', {
    modules: [Navigation],
    slidesPerView: 'auto',
    spaceBetween: 60,
    freeMode: true,
    centeredSlides: true,
    loop: true,
    loopedSlides: 5,
    grabCursor: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  });

// Настройки кнопок для библиотеки сравнения изображений
const imageCompareOptions = {
  controlShadow: false,
  addCircle: true,
  addCircleBlur: false
};

/* eslint-disable max-statements */
$(document).ready(function () {
  initializeFluidSimulation();

  // Функции изменения изображения при передвижении ползунка на странице дизайна
  if (window.location.pathname === '/design') {
    const comparisonElements = document.querySelectorAll('.comparison');

    comparisonElements.forEach((element) => {
      // eslint-disable-next-line no-unused-vars
      const view = new ImageCompare(element, imageCompareOptions).mount();
    });
  }

  // Маска для ввода телефона
  const inputMask = new Inputmask('+7 (999) 999-99-99');
  inputMask.mask('#phone');

  // Сохранение видимости элементов для анимации при появлении
  const itemsInViewport = {};

  // Активация анимаций и запись видимости элементов на странице bi
  if (window.location.pathname === '/bi_analytics_and_dashboards') {
    ticker();

    const biAdvantagesItems = $('.bi_advantages_items').children();
    const biProcess = $('.bi_process');

    itemsInViewport.biAdvantages = biAdvantagesItems.map((index, element) => isInViewport(element));
    itemsInViewport.biProcess = isInViewport(biProcess);

    bi.processItemsSetAttr();
  }

  // Открытие сайдбара по клику на бургерменю и смена иконки
  $('.burger_menu').on('click', function () {
    $('body').css('overflow', 'hidden');
    $('#side_bar').addClass('is_open');

    $('.close_menu').removeClass('d_none');
    $('.burger_menu').addClass('d_none');
  });

  // Закрытие сайдбара по клику на крестик и смена иконки
  $('.close_menu').on('click', function () {
    $('body').css('overflow', 'auto');
    $('#side_bar').removeClass('is_open');

    $('.burger_menu').removeClass('d_none');
    $('.close_menu').addClass('d_none');
  });

  /* eslint-disable complexity */
  $(document).on('scroll', function () {
    // Действия при скроле на странице bi
    if (window.location.pathname === '/bi_analytics_and_dashboards') {
      // Блок преимущества
      const biAdvantagesItems = $('.bi_advantages_items').children();

      biAdvantagesItems.each((index, element) => {
        // если видимость элемента поменялась
        if (!itemsInViewport.biAdvantages[index] && isInViewport(element)) {
          const images = $(element).find('.images_container').children();

          // Анимация появления и исчезания элементов
          [0, 1].includes(index) && imagesToggleSlide(element, images);

          // Анимация выплывающих справа и слева элементов
          if (index === 3) {
            $('#charts_left').addClass('left_appear');

            $('#charts_right').addClass('right_appear');
          }

          // Запись нового состояния в переменную
          itemsInViewport.biAdvantages[index] = isInViewport(element);
        }
      });

      // Блок процесса
      const biProcess = $('.bi_process');
      // если видимость элемента поменялась
      if (!itemsInViewport.biProcess && isInViewport(biProcess)) {
        // Запись нового состояния в переменную
        itemsInViewport.biProcess = isInViewport(biProcess);

        // Запуск анимации
        bi.processAnimation();
      }
    }
  });

  // Клик по логотипу на главной странице скроллит к началу страницы
  $('.logo').on('click', function () {
    if ($(this).closest('a').length === 0) $('html, body').animate({ scrollTop: 0 });
  });

  // Возвращение назад по клику на кнопку Назад
  $('.back_bttn').on('click', function () {
    window.history.back();
  });

  // При тапе/клике на иконку главного блока скролл до следующего
  $('.mouse_icon').on('click', function () {
    scrollTo('.about_block');
  });

  // Раскрытие и сворачивание шага в блоке step by step в мобильной вёрстке по клику
  $('.mobile_step').on('click', function () {
    const id = $(this).attr('id');

    $(`#expand_more_${id}, #expand_less_${id}`).toggleClass('d_none');
    $(`#item_text_${id}`).toggleClass('expanded');
  });

  // При клике на кнопку "Обсудить проект" скролл до формы с заявкой
  $('.title_bttn').on('click', function () {
    scrollTo('.bid', true, 700);
  });

  // Анимация кнопки при наведении
  $('.bttn_animated').on('mouseenter', function () {
    if (!isMobile) {
      $(this).find('.bttn_animated_border_top').removeClass('unactive');
      $(this).find('.bttn_animated_border_top').addClass('active');

      $(this).find('.bttn_animated_border_bottom').removeClass('unactive');
      $(this).find('.bttn_animated_border_bottom').addClass('active');
    }
  });

  // Обратная анимация при уходе курсора с кнопки
  $('.bttn_animated').on('mouseleave', function () {
    if (!isMobile) {
      $(this).find('.bttn_animated_border_top').removeClass('active');
      $(this).find('.bttn_animated_border_top').addClass('unactive');

      $(this).find('.bttn_animated_border_bottom').removeClass('active');
      $(this).find('.bttn_animated_border_bottom').addClass('unactive');
    }
  });

  // Отправка заявки
  $('.form_bttn').on('click', function (e) {
    e.preventDefault();

    const formName = $('.form_input_name').val();
    const formPhone = $('.form_input_phone').val();
    const formContent = $('textarea').val();
    const formData = { username: formName, phone: formPhone, content: formContent };
    const formPhoneСhanged = formPhone.replace(/[^\d]/g, '');

    inputNameCheck(formName);
    inputPhoneCheck(formPhone, formPhoneСhanged);
    inputContentCheck(formContent);

    if (!Object.values(formData).includes('') && formPhoneСhanged.length === 11) {
      $.ajax({
        url: '/api/v1/bids',
        type: 'POST',
        dataType: 'json',
        data: { bid: formData }
      })
        .done(function () {
          $('.popup_bid').fadeIn(1000).css('display', 'flex');
        });
    }
  });

  // Отправка запроса на подписку на новости
  $('#form_bttn_subscribe').on('click', function (e) {
    e.preventDefault();

    const formEmail = $('#email').val();

    const isValid = inputEmailCheck(formEmail);

    if (!isValid) return;

    const formData = { email: formEmail };

    $.ajax({
      url: '/api/v1/subscriptions',
      type: 'POST',
      dataType: 'json',
      data: { subscription: formData }
    })
      .done(function () {
        $('.popup_subscribe_to_newsletter').fadeIn(1000).css('display', 'flex');
      })
      .fail(function () {
        $('.popup_subscribe_to_newsletter_fail').fadeIn(1000).css('display', 'flex');
      });
  });

  // Функция проверки email
  function inputEmailCheck (email) {
    $('#form_input_error_email').fadeOut(100);
    $('.form_input_error_text').html('');

    if (email.length < 1) {
      $('.form_input_error_text').html('Поле не может быть пустым');
      $('#form_input_error_email').fadeIn(1000).css('display', 'flex');

      return false;
    }

    const isValidEmail = emailRegex.test(email);
    if (!isValidEmail) {
      $('.form_input_error_text').html('Введите корректный email');
      $('#form_input_error_email').fadeIn(1000).css('display', 'flex');

      return false;
    }

    return true;
  }

  // Функция проверки инпута имени
  function inputNameCheck (value) {
    if (value.length < 1) $('.form_input_error_name').fadeIn(1000).css('display', 'flex');
  }

  // Функция проверки инпута телефона
  function inputPhoneCheck (value, valueСhanged) {
    if (value.length < 1) $('.form_input_error_phone').fadeIn(1000).css('display', 'flex');
    else if (valueСhanged.length < 11) {
      $('.form_input_error_text_phone').html('Введите корректный номер');
      $('.form_input_error_phone').fadeIn(1000).css('display', 'flex');
    }
  }

  // Функция проверки инпута комменатрия
  function inputContentCheck (value) {
    if (value.length < 1) $('.form_input_error_textarea').fadeIn(1000).css('display', 'flex');
  }

  // Показ/скрытие ошибки в зависимости от содержимого инпута
  $('.form_input_name').on('input', function () {
    const formName = $('.form_input_name').val();
    if (formName.length < 1) $('.form_input_error_name').fadeIn(1000).css('display', 'flex');
    else $('.form_input_error_name').fadeOut(500);
  });

  // Показ/скрытие ошибки в зависимости от содержимого инпута
  $('.form_input_phone').on('input', function () {
    const formPhone = $('.form_input_phone').val();
    if (formPhone.length < 1) $('.form_input_error_phone').fadeIn(1000).css('display', 'flex');
    else $('.form_input_error_phone').fadeOut(500);
  });

  // Показ/скрытие ошибки в зависимости от содержимого инпута
  $('textarea').on('input', function () {
    const formContent = $('textarea').val();
    if (formContent.length < 1) $('.form_input_error_textarea').fadeIn(1000).css('display', 'flex');
    else $('.form_input_error_textarea').fadeOut(500);
  });

  // Закрытие попапа + очистка данных формы
  $('.bid_close_icon').on('click', function (e) {
    const isSubscribePopup = e.currentTarget.className.includes('close_icon_subscribe');

    $(isSubscribePopup ? 'div[class*="popup_subscribe_to_newsletter"]' : '.popup_bid').fadeOut(1000);
    $(isSubscribePopup ? '.subscribe_to_newsletter_form' : '.start_now_block_form')[0].reset();
  });

  // Редирект на главную страницу
  $('.popup_bttn').on('click', function () {
    $(window.location).attr('href', '/');
  });

  // Редирект на страницу блога
  $('.popup_bttn_posts').on('click', function () {
    $(window.location).attr('href', '/posts');
  });

  // Появление надписи около иконки WhatsApp в хедере при наведении
  $('.header_whats_app_logo').on('mouseenter', function () {
    $('.header_whats_app_text').fadeIn();
  });

  // Исчезновение надписи около иконки WhatsApp в хедере при наведении
  $('.header_whats_app_logo').on('mouseleave', function () {
    $('.header_whats_app_text').fadeOut();
  });

  // Анимация по клику на иконку в блоке процесс на странице bi если открыто с мобильного устройства
  $('div[class^="process_item_icon"]').on('click', function () {
    bi.onClickIconProcess(this);
  });

  // Инициализация слушателей для многошаговой заявки
  initBid();
});

// Регулярное выражение для проверки email
// eslint-disable-next-line no-useless-escape
const emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
