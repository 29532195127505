import webGLFluidEnhanced from 'webgl-fluid-enhanced';

// Опции для конфигурации симуляции всплесков
const configOptions = {
  COLOR_PALETTE: ['#58f5ff', '#fb3ebc', '#ce7efb', '#5323bd', '#a62090'], // Пользовательская цветовая палитра (по умолчанию пустая, используются шестнадцатеричные цвета)
  BACK_COLOR: '#262026', // Цвет фона холста, по умолчанию '#000000',
  VELOCITY_DISSIPATION: 0.2, // Скорость, с которой рассеиваются всплески, по умолчанию 0.2
  SPLAT_RADIUS: 0.25, // Радиус всплесков, по умолчанию 0.25
  SPLAT_AMOUNT: 5, // Количество начальных всплесков (случайное число от n до n * 5)
  SPLAT_FORCE: 6000, // Сила, приложенная к всплескам, по умолчанию 6000
  SIM_RESOLUTION: 128, // Разрешение сетки симуляции, по умолчанию 128
  DYE_RESOLUTION: 1024, // Разрешение сетки для краски, по умолчанию 1024
  CAPTURE_RESOLUTION: 512, // Разрешение захваченных кадров, по умолчанию 512
  DENSITY_DISSIPATION: 1, // Скорость, с которой рассеивается плотность, по умолчанию 1
  PRESSURE: 0.8, // Значение давления, используемое в симуляции, по умолчанию 0.8
  PRESSURE_ITERATIONS: 20, // Количество итераций давления, по умолчанию 20
  CURL: 30, // Значение завихрения, используемое в симуляции, по умолчанию 30
  INITIAL: true, // Включает всплески при начальной загрузке
  SPLAT_KEY: '', // Клавиша для создания новых всплесков (оставьте пустым, чтобы отключить)
  SHADING: true, // Включает затенение в визуализации
  COLORFUL: true, // Включает быстрое изменение цветов
  COLOR_UPDATE_SPEED: 10, // Скорость обновления цвета, по умолчанию 10
  HOVER: true, // Включает взаимодействие при наведении
  TRANSPARENT: false, // Делает холст прозрачным, если true
  BRIGHTNESS: 0.5, // Яркость цвета (рекомендуется ниже 1,0, если BLOOM включен), по умолчанию 0.5
  BLOOM: false, // Включает эффект свечения
  BLOOM_ITERATIONS: 8, // Количество итераций эффекта свечения, по умолчанию 8
  BLOOM_RESOLUTION: 256, // Разрешение эффекта свечения, по умолчанию 256
  BLOOM_INTENSITY: 0.8, // Интенсивность эффекта свечения, по умолчанию 0.8
  BLOOM_THRESHOLD: 0.6, // Порог эффекта свечения, по умолчанию 0.6
  BLOOM_SOFT_KNEE: 0.7, // Мягкий переход для эффекта свечения, по умолчанию 0.7
  SUNRAYS: false, // Включает эффект солнечных лучей
  SUNRAYS_RESOLUTION: 196, // Разрешение эффекта солнечных лучей, по умолчанию 196
  SUNRAYS_WEIGHT: 1.0 // Вес эффекта солнечных лучей, по умолчанию 1.0
};

// Массив событий для отслеживания действий в симуляции всплесков
const simulationEvents = ['click', 'mousemove', 'mousedown', 'mouseup', 'mouseover', 'mouseout'];

// Инициализировать симуляцию брызг
export const initializeFluidSimulation = () => {
  const canvas = document.querySelector('canvas');
  const div = document.querySelector('div');

  const handleMouseEvent = (event) => {
    propagateMouseEvent(event, canvas);
  };

  // Устанавливаем слушатели событий мыши
  if (div) {
    simulationEvents.forEach(event => div.addEventListener(event, handleMouseEvent));
  }

  if (canvas) {
    webGLFluidEnhanced.simulation(canvas, configOptions);
  }

  // Очистка слушателей событий
  const cleanup = () => {
    if (div) {
      simulationEvents.forEach(event => div.removeEventListener(event, handleMouseEvent));
    }
  };

  window.addEventListener('beforeunload', cleanup);
};

// Отправить событие мыши
function propagateMouseEvent (event, target) {
  if (target) {
    target.dispatchEvent(new MouseEvent(event.type, event));
  }
}
